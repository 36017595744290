import axios, { AxiosInstance } from 'axios';

class AxiosSingleton {
    private static instance: AxiosInstance;

    private constructor() { }

    public static getInstance(): AxiosInstance {
        if (!AxiosSingleton.instance) {
            AxiosSingleton.instance = axios.create({
                baseURL: process.env.REACT_APP_API_URL,
                headers: {'Accept': 'application/json'}
            });

            AxiosSingleton.instance.interceptors.request.use(
                config => {
                    const token = localStorage.getItem('token');
                    if (token) {
                        config.headers['Authorization'] = `Bearer ${token}`;
                    }
                    return config;
                },
                error => {
                    // Faça algo com o erro da requisição
                    return Promise.reject(error);
                }
            );

            // Interceptores de resposta
            AxiosSingleton.instance.interceptors.response.use(
                response => {
                    return response;
                },
                error => {
                    // if (error.response && error.response.status === 401) {
                    //     // Redireciona para a página de login
                    //     window.location.href = '/login';
                    // }
                    return Promise.reject(error);
                }
            );
        }

        return AxiosSingleton.instance;
    }
}

export default AxiosSingleton;
