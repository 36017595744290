import { AxiosError } from 'axios';
import { DefaultResponse } from '../types/DefaultResponse';
import AxiosSingleton from './AxiosSingleton';

const processImportServiceImport = async (data: any) => {

    let formData = new FormData();
    if (data?.typeProcessImportId === 1) {
        formData.append('type_use_words', data.typeUseWords);
        for (let i = 0; i < data.keywords.length; i++) {
            formData.append(`searches_config[${i}]`, data.keywords[i]);
        }
        formData.append('type_process_import_id', data.typeProcessImportId);
    } else {
        formData.append('type_process_import_id', data.typeProcessImportId);
    }

    formData.append('file', data.file);
    const headers = { "Content-Type": "multipart/form-data" };

    try {
        const response = await AxiosSingleton
            .getInstance()
            .post('/api/process-import', formData, { headers });
        return response.data;
    } catch(error) {
        if (error instanceof AxiosError) {
            return { success: false, code: error.response?.status, data: error.response?.data?.message} as DefaultResponse;
        }
    }

}

export const ProcessImportService = {
    processImportServiceImport
};
