import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { ButtonTogglePassword, FormFloating, FormSignin, LogoImage, PasswordInput, UsernameInput, WrapSpinnerLogin } from "../Login/LoginPageStyles";
import { Alert, Form, Spinner } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import AxiosSingleton from "../../services/AxiosSingleton";
import logo from './../../assets/logopretopng.png';

interface FromResetPasswordProps {
    setIdPasswordReseted: React.Dispatch<React.SetStateAction<boolean>>;
}

const ResetPasswordPage: React.FC = () => {
    const [isPasswordReseted, setIdPasswordReseted] = useState<boolean>(false);

    return (
        <>
            {
                isPasswordReseted
                    ? <FormResetPasswordReseted />
                    : <FormResetPassword setIdPasswordReseted={setIdPasswordReseted} />
            }
        </>
    );
};

const FormResetPassword: React.FC<FromResetPasswordProps> = ({ setIdPasswordReseted }) => {

    const location = useLocation();

    const [token, setToken]                                 = useState<string|null>("");
    const [showPassword, setShowPassword]                   = useState<boolean>(false);
    const [passwordValue, setPasswordValue]                 = useState<string>('')
    const [showConfirPassword, setShowConfirmPassword]      = useState<boolean>(false);
    const [confirmPasswordValue, setConfirmPasswordValue]   = useState<string>('')
    const [isLoading, setIsLoading]                         = useState<boolean>(false);
    const [error, setError]                                 = useState<string|null>(null);

    // eslint-disable-next-line
    useEffect(() => setToken(new URLSearchParams(location.search).get("token")),[]);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setError(null);

        if (!passwordValue || !confirmPasswordValue) {
            setError("Preencha todos os campos!");
            return;
        }

        if (passwordValue.length < 6) {
            setError("A senha deve ter no mínimo 6 caracteres.");
            return;
        }

        if (passwordValue !== confirmPasswordValue) {
            setError("As senhas não conferem!");
            return;
        }

        setIsLoading(true);
        try {
            await AxiosSingleton.getInstance().post('/api/auth/reset-password', {
                token,
                password: passwordValue,
                password_confirmation: confirmPasswordValue
            });

            setIdPasswordReseted(true);
        } catch (error: any) {
            let message = 'Erro não identificado, contate o suporte ou tente novamente mais tarde!';
            if ([401, 422].includes(error.response.status)) {
                message = error.response.data.message;
            }
            setError(message);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="d-flex flex-column justify-content-center align-items-center h-100">
            <FormSignin className='w-100'>
                <Form onSubmit={handleSubmit}>
                    <h1 className="h3 mb-3 fw-normal text-center">
                        <LogoImage src={logo} style={{ maxWidth: 72 }} />
                    </h1>
                    <FormFloating>
                        <UsernameInput
                            type={showPassword ? 'text' : 'password'}
                            className="form-control"
                            id="password"
                            placeholder="Senha"
                            value={passwordValue}
                            onChange={(e) => setPasswordValue(e.target.value)}
                        />
                        <ButtonTogglePassword
                            type="button"
                            onClick={() => setShowPassword(!showPassword)}
                            style={{ borderTopRightRadius: 4, borderBottomRightRadius: 0 }}
                        >
                            <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} />
                        </ButtonTogglePassword>
                        <label htmlFor="password">Senha</label>
                    </FormFloating>

                    <FormFloating>
                        <PasswordInput
                            type={showConfirPassword ? 'text' : 'password'}
                            className="form-control"
                            id="confirm-password"
                            placeholder="Confirmar Senha"
                            value={confirmPasswordValue}
                            onChange={(e) => setConfirmPasswordValue(e.target.value)}
                        />
                        <ButtonTogglePassword
                            type="button"
                            onClick={() => setShowConfirmPassword(!showConfirPassword)}
                        >
                            <FontAwesomeIcon icon={showConfirPassword ? faEye : faEyeSlash} />
                        </ButtonTogglePassword>
                        <label htmlFor="confirm-password">Confirmar Senha</label>
                    </FormFloating>

                    <div className="d-flex flex-column align-items-center mt-2">
                        <button
                            className={`btn btn-primary py-2 px-5 position-relative${isLoading ? ' disabled' : ''}`}
                            disabled={isLoading}
                            type="submit"
                        >
                            {
                                isLoading
                                    ?
                                        (
                                            <>
                                                <span>Alterando</span>
                                                <WrapSpinnerLogin>
                                                    <Spinner color="light" size="sm" />
                                                </WrapSpinnerLogin>
                                            </>
                                        )
                                    :
                                        (
                                            "Alterar Senha"
                                        )
                            }
                        </button>
                        <Link to="/login" className="mt-2">
                            Sair
                        </Link>
                    </div>
                </Form>
            </FormSignin>
            {
                error && <Alert color="danger">{error}</Alert>
            }
        </div>
    );
};

const FormResetPasswordReseted = () => {
    return (
        <div className="d-flex justify-content-center align-items-center h-100">
            <div className="text-center">
                <p>Senha alterada com sucesso!</p>
                <Link to="/login" className="mt-2">
                    Login
                </Link>
            </div>
        </div>
    );
};

export default ResetPasswordPage;