import React from "react";
import { useAuth } from "../../providers/Auth/AuthProvider";
import { Container } from 'reactstrap';

import DefaultHeader from '../../components/Hearders/DefaultHeader';

import { LogoImage } from "./HomePageStyles";
import logo from './../../assets/logosvg.svg';

export default function HomePage() {
    const { user } = useAuth();

    return (
        <>
            <DefaultHeader />
            <Container fluid>
                <div className="px-4 py-5 my-5 text-center">
                    <LogoImage src={logo} />
                    <h1 className="display-5 fw-bold text-body-emphasis">
                        SYSVRP Backend
                    </h1>
                    <div className="col-lg-6 mx-auto">
                        <p className="lead mb-4">
                            {user?.alias ?? (user?.personCpf?.name ?? user?.personCnpj?.fantasyName)}
                        </p>
                    </div>
                </div>
            </Container>
        </>
    );
}
