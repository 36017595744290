import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import { Button, Spinner } from "reactstrap";
import { FormFloating, FormSignin, LogoImage, UsernameInput, WrapSpinnerLogin } from "../Login/LoginPageStyles";

import logo from './../../assets/logopretopng.png';


interface FormForgotPasswordProps {
    setIsLinkSent: React.Dispatch<React.SetStateAction<boolean>>;
}

const ForgotPasswordPage: React.FC = () => {
    const [isLinkSent, setIsLinkSent] = useState<boolean>(false);

    return (
        <div className="d-flex justify-content-center align-items-center h-100">
            { isLinkSent
                ? <FormForgotPasswordSent />
                : <FormForgotPassword setIsLinkSent={setIsLinkSent} />
            }
        </div>
    );
};

const FormForgotPassword: React.FC<FormForgotPasswordProps> = ({ setIsLinkSent }) => {
    const navigate = useNavigate();
    const [username, setUsername] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setIsLoading(true);

        try {
            const api = axios.create({baseURL: process.env.REACT_APP_API_URL,headers: {'Accept': 'application/json'}});
            await api.post(`/api/auth/forgot-password`, { username });
        } catch(e) {
        }

        setIsLoading(false);
        setIsLinkSent(true);
    };

    return (
        <FormSignin className="w-100">
            <form onSubmit={handleSubmit}>
                <h1 className="h3 mb-3 fw-normal text-center">
                    <LogoImage src={logo} style={{maxWidth: 72}}/>
                </h1>
                <FormFloating>
                    <UsernameInput
                        type="text"
                        className="form-control rounded"
                        id="username"
                        placeholder="Usuário"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        required
                    />
                    <label htmlFor="username">Usuário</label>
                </FormFloating>
                <div className="d-flex flex-column align-items-center mt-2">
                    <button
                        className={`btn btn-primary py-2 px-5 position-relative${isLoading ? ' disabled' : ''}`}
                        disabled={isLoading}
                        type="submit"
                    >
                        Enviar Link
                        {isLoading && (
                            <WrapSpinnerLogin>
                                <Spinner color="light" size="sm" />
                            </WrapSpinnerLogin>
                        )}
                    </button>
                    <Button color="link" onClick={() => navigate(-1)}>
                        Voltar
                    </Button>
                </div>
            </form>
        </FormSignin>
    );
};

const FormForgotPasswordSent = () => {
    const navigate = useNavigate();
    return (
        <div className="text-center">
            <p>Link enviado para o e-mail do cadastro!</p>
            <Button color="link" onClick={() => navigate(-1)}>
                Voltar
            </Button>
        </div>
    );
}

export default ForgotPasswordPage;
