import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row, Spinner, Table } from "reactstrap";

import DefaultHeader from "../../../components/Hearders/DefaultHeader";
import AxiosSingleton from "../../../services/AxiosSingleton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudArrowDown, faLinkSlash } from "@fortawesome/free-solid-svg-icons";

interface FileItems {
    id?: number;
    file: string;
    createdAt: Date;
}

interface RowTableProps {
    index: number,
    file: string,
    createdAt: Date,
    key?: number,
    id?:number
}

const BradescoReport = () => {
    const [items, setItems] = useState<FileItems[]>([]);

    useEffect(() => {
        (async () => {
            try {
                const response = await AxiosSingleton.getInstance().get(`/api/report-monthly`);
                const { data } = response.data;

                setItems(data.map((item: any) => ({
                    id: item.id,
                    file: item.file,
                    createdAt: new Date(item.createdAt)
                })));
            } catch (error) {
                console.error("Erro ao buscar os relatórios:", error);
            }
        })()
    }, []);

    return (
        <>
            <DefaultHeader />
            <Container>
                <Row>
                    <Col>
                        <Table hover striped>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Arquivo</th>
                                    <th className="text-end">Cadastro</th>
                                    <th className="text-center">Opções</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    items.length === 0
                                        ? (<tr><td className="text-center" colSpan={4}>Sem Elementos</td></tr>)
                                        : (
                                            items.map((item, index) => (
                                                <RowTable
                                                    key={index}
                                                    index={index + 1}
                                                    file={item.file}
                                                    createdAt={item.createdAt}
                                                    id={item.id}
                                                />
                                            ))
                                        )
                                }
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

const RowTable: React.FC<RowTableProps> = ({ index, file, createdAt, id }) => {
    return (
        <tr>
            <th>{index}</th>
            <td>{file}</td>
            <td className="text-end">{createdAt.toLocaleString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit' })}</td>
            <td className="text-center">
                <ButtonDownload id={id} file={file} />
            </td>
        </tr>
    );
};

const ButtonDownload = ({ id, file }: any) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError]     = useState(false);


    const handleDownload = async () => {
        try {
            setIsLoading(true);
            const response = await AxiosSingleton.getInstance().get(`/api/report-monthly/download/${id}`, {responseType: 'blob'});

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', file);
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (error) {
            console.error("Erro ao fazer o download:", error);
            setIsError(true);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <Button
                className={`me-1 ${isError || isLoading ? 'disabled' : ''}`}
                size="sm"
                color={isError ? 'danger' : 'primary'}
                onClick={() => handleDownload()}
                disabled={isError || isLoading}
                outline={isError}
            >
                {
                    isLoading ? <Spinner size={'sm'} /> : <FontAwesomeIcon icon={isError ? faLinkSlash : faCloudArrowDown} />
                }
            </Button>
        </>
    );
}


export default BradescoReport;
