import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, FormGroup, Input, Label, Row, Spinner } from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faKey, faSave, faUser } from "@fortawesome/free-solid-svg-icons";

import { useAuth } from '../../providers/Auth/AuthProvider';

import DefaultHeader from "../../components/Hearders/DefaultHeader";
import { Link } from "react-router-dom";
import AxiosSingleton from "../../services/AxiosSingleton";
import { WrapSpinnerProdile } from "./ProfilePageStyles";


const ProfilePage = () => {
    const { user, me } = useAuth();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        if (user) {
            setEmail(user.contacts?.[0]?.data ?? '');
            setName(user.personCpf?.name ?? '');
            setCpf(user.personCpf?.cpf ?? '');
            setRg(user.personCpf?.rg ?? '');
            setBirthdate(user.personCpf?.birthDate ? new Date(user.personCpf.birthDate).toISOString().split('T')[0] : '');
        }
    }, [user]);

    const [name, setName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [cpf, setCpf] = useState<string>('');
    const [rg, setRg] = useState<string>('');
    const [birthdate, setBirthdate] = useState<string>('');

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setIsLoading(true);

        await AxiosSingleton.getInstance().post(`/api/users/save/${user?.id}`, {
            email, name, cpf, rg, birthdate
        });
        me();

        setIsLoading(false);
    };

    return (
        <>
            <DefaultHeader />
            <Container className="mt-3">
                <Row>
                    <Col>
                        <Form method="post" onSubmit={handleSubmit}>
                            <Row>
                                <Col>
                                    <h1>
                                        Dados Pessoais
                                        <small className="ms-3">
                                            <FontAwesomeIcon icon={faUser} size="sm"/>
                                        </small>
                                    </h1>
                                </Col>
                            </Row>

                            <Row>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label htmlFor="email">
                                            E-mail
                                        </Label>
                                        <Input
                                            id="email"
                                            name="email"
                                            placeholder="E-mail"
                                            type="email"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            required
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label htmlFor="name">
                                            Nome
                                        </Label>
                                        <Input
                                            id="name"
                                            name="name"
                                            placeholder="Nome"
                                            type="text"
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                            required
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row>
                                <Col md={4}>
                                    <FormGroup>
                                        <Label htmlFor="CPF">
                                            CPF
                                        </Label>
                                        <Input
                                            id="cpf"
                                            name="cpf"
                                            placeholder="CPF"
                                            type="text"
                                            value={cpf}
                                            onChange={(e) => setCpf(e.target.value)}
                                            required
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={4}>
                                    <FormGroup>
                                        <Label htmlFor="rg">
                                            RG
                                        </Label>
                                        <Input
                                            id="rg"
                                            name="rg"
                                            placeholder="rg"
                                            type="text"
                                            value={rg}
                                            onChange={(e) => setRg(e.target.value)}
                                            required
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={4}>
                                    <FormGroup>
                                        <Label htmlFor="birthdate">
                                            Data de Nascimento
                                        </Label>
                                        <Input
                                            id="birthdate"
                                            name="birthdate"
                                            placeholder="Birthdate"
                                            type="date"
                                            value={birthdate}
                                            onChange={(e) => setBirthdate(e.target.value)}
                                            required
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row>
                                <Col className="text-end">
                                    <Link className="btn btn-secondary me-1" to={'/forgot-password'}>
                                        Alterar Senha
                                        <FontAwesomeIcon className="ms-1" icon={faKey} />
                                    </Link>
                                    <Button 
                                        color="primary" 
                                        type="submit"
                                        className={`px-5 position-relative${ isLoading ? ` disabled` : '' }`} 
                                        disabled={isLoading}
                                    >
                                        Salvar
                                        <FontAwesomeIcon className="ms-1" icon={faSave} />
                                        {
                                            isLoading
                                                ? <WrapSpinnerProdile>
                                                    <Spinner color="light" size='sm' />
                                                </WrapSpinnerProdile>
                                                : null
                                        }
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default ProfilePage;