import {User} from "../types/user";
import AxiosSingleton from './AxiosSingleton';
import { createUserModel, UserResponse } from "./UserService";

interface LoginData {
    username: string;
    password: string;
}

const login = async (loginData: LoginData): Promise<{user: User, token?: string}> => {
    const response = await AxiosSingleton.getInstance().post(`/api/auth/login`, loginData);

    const loginResponse = response.data as UserResponse;
    const user          = createUserModel(loginResponse);
    const token         = response.data.data.token;

    return {user, token};
};

const logout = async (): Promise<void> => {
    await AxiosSingleton.getInstance().delete(`/api/auth/logout`);
};

const me = async (): Promise<User> => {
    const response = await AxiosSingleton.getInstance().get(`/api/auth/me`);
    const meResponse = response.data as UserResponse;
    return createUserModel(meResponse);
}

export const authService = {
    login,
    logout,
    me,
};
