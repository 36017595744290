import React, { useRef, useState, ChangeEvent, useEffect } from "react";
import { Alert, Button, Col, Container, FormFeedback, FormGroup, Input, InputGroup, Label, Row, Spinner } from "reactstrap";

import { ProcessImportService } from "../../services/ProcessImportService";
import { WrapSpinnerProcessImport } from "./ProcessImportStyles";

import DefaultHeader from "../../components/Hearders/DefaultHeader";

interface KeywordInputProps {
    keywords: string[];
    setKeywords: React.Dispatch<React.SetStateAction<string[]>>;
    addKeyword: () => void;
}

const ProcessImportPage: React.FC = () => {
    useEffect(() => setIsLoading(false), []);

    const [keywords, setKeywords] = useState<string[]>(['']);
    const [typeUseWords, setTypeUseWords] = useState<string>('separates');
    const [errors, setErrors] = useState<string[]>([]);
    const [messages, setMessages] = useState<string[]>([]);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const fileInputRef = useRef<HTMLInputElement>(null);

    const addKeyword = () => {
        const existsEmptyElements = keywords.filter(i => !i);
        if (!existsEmptyElements.length) {
            setKeywords([...keywords, ""]);
        }
    };

    const handleTypeUseWords = (event: ChangeEvent<HTMLInputElement>) => setTypeUseWords(event.target.value);

    const handleSend = async () => {
        const newErrors: string[] = [];
        if (!keywords.length) {
            newErrors.push('Adicione pelo menos uma palavra-chave.');
        }

        if (!selectedFile) {
            newErrors.push('Selecione um Arquivo.');
        }

        setIsLoading(true);
        setErrors(newErrors);

        if (newErrors.length === 0) {
            const { success, data } = await ProcessImportService.processImportServiceImport({
                keywords,
                file: selectedFile,
                typeUseWords: typeUseWords,
                typeProcessImportId: 1
            });

            if (success) {
                setKeywords([]);
                setErrors([]);
                setSelectedFile(null);
                if (fileInputRef.current) {
                    fileInputRef.current.value = "";
                }
                setMessages(['Arquivo importado com sucesso!']);
                setTimeout(() => setMessages([]), 3000);
            } else {
                if (data){
                    setErrors([data]);
                } else {
                    setErrors(['Erro inesperado, contate o suporte ou tente novamente mais tarde!']);
                }
            }
        }
        setIsLoading(false);
    }

    return (
        <>
            <DefaultHeader />
            <Container>
                <Row>
                    <Col>
                        <h1 className="mt-3 text-uppercase">Robô Caça Palavras</h1>
                        <FormGroup className="mt-5">
                            <div className="d-flex justify-content-between mb-1">
                                <Label htmlFor="inputfile" className="m-0">
                                    Selecione a planilha com os casos.
                                </Label>
                                <a href="/examples.xlsx" target="_blank">Download Planilha Modelo</a>
                            </div>
                            <Input
                                id="inputfile"
                                name="inputfile"
                                type="file"
                                multiple={false}
                                innerRef={fileInputRef}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    if (event.currentTarget.files) {
                                        setSelectedFile(event.currentTarget.files[0]);
                                    }
                                }}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col>
                        <div className="d-flex justify-content-between align-items-center">
                            <Label onClick={addKeyword} className="m-0">Frases a serem pesquisadas:</Label>
                            <div className="d-flex align-items-center">
                                <FormGroup check>
                                    <Input
                                        type="radio"
                                        name="radioTypeUseWords"
                                        id="typeUseWordSeparated"
                                        value={'separates'}
                                        defaultChecked
                                        onChange={handleTypeUseWords}
                                    />
                                    <Label check htmlFor="typeUseWordSeparated">
                                        Separadas
                                    </Label>
                                </FormGroup>
                                <FormGroup check className="mx-3">
                                    <Input
                                        type="radio"
                                        name="radioTypeUseWords"
                                        id="typeUseWordAll"
                                        value={'all'}
                                        onChange={handleTypeUseWords}
                                    />
                                    <Label check htmlFor="typeUseWordAll">
                                        Completo
                                    </Label>
                                </FormGroup>
                                <Button color="primary" onClick={addKeyword}>
                                    Adicionar
                                </Button>
                            </div>
                        </div>
                        <hr className="mt-1" />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <KeywordInput keywords={keywords} setKeywords={setKeywords} addKeyword={addKeyword} />
                    </Col>
                </Row>
                <Row>
                    <Col className="text-center">
                        <Button
                            color="primary"
                            className={`w-100 mt-3 position-relative${isLoading ? ` disabled` : ''}`}
                            disabled={isLoading}
                            style={{ maxWidth: 300 }}
                            type="button"
                            onClick={handleSend}
                        >
                            Enviar
                            {
                                isLoading
                                    ? <WrapSpinnerProcessImport><Spinner color='light' size='sm' /></WrapSpinnerProcessImport>
                                    : null
                            }
                        </Button>
                    </Col>
                </Row>
                <Message messages={errors} type="danger" />
                <Message messages={messages} type="success" />
            </Container>
        </>
    );
}

const Message: React.FC<{ messages: string[], type: string }> = ({ messages, type }) => {
    return messages.length > 0 ? (
        <Row className="mt-5">
            <Col>
                {messages.map((msg, index) => <Alert key={index} color={type}>{msg}</Alert>)}
            </Col>
        </Row>
    ) : null;
}

const KeywordInput: React.FC<KeywordInputProps> = ({ keywords, setKeywords, addKeyword }) => {
    const [invalidIndices, setInvalidIndices] = useState<number[]>([]);
    const inputRefs = useRef<(HTMLInputElement | HTMLTextAreaElement | null)[]>([]);

    const handleKeywordChange = (index: number, value: string) => {
        const newKeywords = [...keywords];
        newKeywords[index] = value;
        setKeywords(newKeywords);
        validateKeywords(newKeywords);
    };

    const removeKeyword = (index: number) => {
        const newKeywords = keywords.filter((_, i) => i !== index);
        setKeywords(newKeywords);
        validateKeywords(newKeywords);
    };

    const validateKeywords = (newKeywords: string[]) => {
        const keywordMap: { [key: string]: number[] } = {};
        const duplicates: number[] = [];

        newKeywords.forEach((keyword, index) => {
            if (keywordMap[keyword]) {
                keywordMap[keyword].push(index);
            } else {
                keywordMap[keyword] = [index];
            }
        });

        Object.values(keywordMap).forEach(indices => {
            if (indices.length > 1) {
                duplicates.push(...indices);
            }
        });

        setInvalidIndices(duplicates);
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>, index: number) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            addKeyword();
            setTimeout(() => {
                const nextInput = inputRefs.current[index + 1];
                if (nextInput) {
                    nextInput.focus();
                }
            }, 0);
        }
    };

    useEffect(() => {
        inputRefs.current = inputRefs.current.slice(0, keywords.length);
    }, [keywords]);

    return (
        <>
            {keywords.map((keyword, index) => (
                <FormGroup key={index}>
                    <Label for={`keyword${index}`}>
                        {index + 1} - Palavra<small style={{ fontSize: "70%" }}>(s)</small>:
                    </Label>
                    <InputGroup>
                        <Input
                            id={`keyword${index}`}
                            value={keyword}
                            innerRef={el => inputRefs.current[index] = el}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => handleKeywordChange(index, e.target.value)}
                            onKeyDown={(e) => handleKeyDown(e, index)}
                            invalid={invalidIndices.includes(index)}
                        />
                        <Button
                            color="danger"
                            onClick={() => removeKeyword(index)}
                        >
                            Remover
                        </Button>
                        {
                            invalidIndices.includes(index) && <FormFeedback>Duplicidade</FormFeedback>
                        }
                    </InputGroup>
                </FormGroup>
            ))}
        </>
    );
}

export default ProcessImportPage;
