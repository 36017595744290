import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate, Link} from 'react-router-dom';

import { Form, Spinner, Toast, ToastBody, ToastHeader } from 'reactstrap';
import { ButtonTogglePassword, FormFloating, FormSignin, LogoImage, PasswordInput, UsernameInput, WrapSpinnerLogin } from './LoginPageStyles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

import {useAuth} from "../../providers/Auth/AuthProvider";

import logo from './../../assets/logopretopng.png';

const LoginPage: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { login } = useAuth();

    useEffect(() => setIsLoading(false), []);

    const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');

    const [showPassword, setShowPassword] = useState<boolean>(false);
    const toggleShowPassword = () => setShowPassword(!showPassword);

    const [messageLogin, setMessageLogin] = useState<{ type: string, message: string } | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const from = (location.state as { from: { pathname: string } })?.from?.pathname || "/";

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        try {
            setIsLoading(true);
            await login(username, password);
            setMessageLogin({type: "success",message: "Login efetuado com sucesso!"});
            setTimeout(() => navigate(from, {replace: true}), 1000);
        } catch (error: any) {
            let message = "Erro ao efetuar login.";
            if (error?.response?.status === 422) {
                message = error?.response?.data?.message || "Dados inválidos. Por favor, tente novamente.";
            } else if (error?.response?.status === 401) {
                message = "Credenciais incorretas. Verifique seu username e senha.";
            } else if (error?.response) {
                message = `Erro ${error.response.status}: ${error.response.statusText}`;
            }

            setMessageLogin({type: "danger",message});
            setIsLoading(false);
            setTimeout(() => setMessageLogin(null), 5000);
        }
    };

    return (
        <>
            <div className="d-flex justify-content-center align-items-center h-100">
                <FormSignin className='w-100'>
                    <Form onSubmit={handleSubmit}>
                        <h1 className="h3 mb-3 fw-normal text-center">
                            <LogoImage src={logo} style={{maxWidth: 72}}/>
                        </h1>
                        <FormFloating>
                            <UsernameInput
                                type='text'
                                className="form-control"
                                id="username"
                                placeholder="Usuário"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                            />
                            <label htmlFor="username">Usuário</label>
                        </FormFloating>
                        <FormFloating>
                            <PasswordInput
                                type={showPassword ? 'text' : 'password'}
                                className="form-control"
                                id="password"
                                placeholder="Senha"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <ButtonTogglePassword type="button" onClick={toggleShowPassword}>
                                <FontAwesomeIcon  icon={showPassword ? faEye : faEyeSlash} />
                            </ButtonTogglePassword>
                            <label htmlFor="password">Senha</label>
                        </FormFloating>
                        <button
                            className={`btn btn-primary w-100 py-2 position-relative${ isLoading ? ` disabled` : '' }`}
                            disabled={isLoading}
                            type="submit"
                        >
                            Entrar
                            {
                                isLoading
                                    ? <WrapSpinnerLogin>
                                        <Spinner color='light' size='sm'/>
                                        </WrapSpinnerLogin>
                                    : null
                            }
                        </button>
                        <div className="w-100 text-end">
                            <Link to={'/forgot-password'}>
                                <small>Esqueceu a senha?</small>
                            </Link>
                        </div>
                    </Form>
                </FormSignin>
            </div>
            {
                messageLogin
                    ? <LoginMessage type={messageLogin.type} message={messageLogin.message} />
                    : null
            }
        </>
    );
};

const LoginMessage = ({ type, message }: { type: string, message: string }) => {
    return (
        <Toast style={{ position: "absolute", top: "20px", right: "20px" }}>
            <ToastHeader icon={`${ type }`}>
                SYSVRP
            </ToastHeader>
            <ToastBody>
                {message}
            </ToastBody>
        </Toast>
    );
}

export default LoginPage;
