import React, {createContext, useContext, useEffect, useState} from 'react';

import {authService} from '../../services/authService';
import {User} from '../../types/user';

interface AuthContextType {
    user: User | null;
    isAuthenticated: boolean;
    login: (username: string, password: string) => Promise<void>;
    logout: () => Promise<void>;
    me: () => Promise<void>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({children}) => {
    const [user, setUser] = useState<User | null>(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const initializeAuth = async () => {
            const storedUser = localStorage.getItem('user');
            if (storedUser) {
                setUser(JSON.parse(storedUser));
            }
            setLoading(false);
        };
        initializeAuth();
    }, []);

    const login = async (username: string, password: string) => {
        try {
            const {user, token} = await authService.login({username, password});
            localStorage.setItem('user', JSON.stringify(user));
            localStorage.setItem('token', token??'');
            setUser(user);
        } catch (error) {
            console.log('Erro ao login:', error);
            throw error;
        }
    };

    const logout = async () => {
        try {
            await authService.logout();
        } catch (error) {
            console.log('Erro ao logout:', error);
        } finally {
            localStorage.removeItem('user');
            localStorage.removeItem('token');
            setUser(null);
        }

    };

    const me = async () => {
        try {
            const user = await authService.me();  // Faz a requisição para obter os dados do usuário
            localStorage.setItem('user', JSON.stringify(user));  // Armazena o usuário no localStorage
            setUser(user);  // Atualiza o estado do usuário no contexto
        } catch (error: any) {
            // Verifique se o erro tem uma resposta e se o status é 401 (não autorizado)
            if (error?.response?.status === 401) {
                // Limpa o estado do usuário localmente
                localStorage.removeItem('user');
                localStorage.removeItem('token');
                setUser(null);  // Remove o usuário do estado
            } else {
                console.log('Erro ao buscar usuário autenticado:', error);  // Loga outros erros
            }
        }
    };

    const value = {
        user,
        isAuthenticated: user !== null,
        login,
        logout,
        me,
    };

    if (loading) {
        return <div>Loading...</div>; // Você pode substituir isso por um componente de carregamento adequado
    }

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};
