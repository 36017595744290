import { useEffect, useState } from 'react';
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownItem,
    DropdownMenu
} from 'reactstrap';
import { useAuth } from '../../providers/Auth/AuthProvider';
import { useLocation } from 'react-router';

const DefaultHeader = (args: any) => {
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);
    const { user, logout, me } = useAuth();
    const location = useLocation();

    // eslint-disable-next-line
    useEffect(() => {me()}, [location.pathname]);

    return (
        <Navbar {...args} expand={'md'} container={'sm'} className={'border-bottom shadow-sm'}>
            <NavbarBrand href="/">
                SYSVRP
            </NavbarBrand>
            <NavbarToggler onClick={toggle} />
            <Collapse isOpen={isOpen} navbar>
                <Nav className="me-auto" navbar>
                    <NavItem>
                        <NavLink to="/">Home</NavLink>
                    </NavItem>
                    <UncontrolledDropdown nav inNavbar>
                        <DropdownToggle nav caret>
                            Configurar Robô
                        </DropdownToggle>
                        <DropdownMenu end>
                            <DropdownItem href='/search-config'>Caça Palavras</DropdownItem>
                            <DropdownItem href='/movements'>Movimentação e Capa</DropdownItem>
                            <DropdownItem href='/full-download-register'>Download Integral</DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                    <UncontrolledDropdown nav inNavbar>
                        <DropdownToggle nav caret>
                            Resultado Buscas
                        </DropdownToggle>
                        <DropdownMenu end>
                            <DropdownItem href='/batches-searches'>Caça Palavras</DropdownItem>
                            <DropdownItem href='/movements-download'>Movimentação e Capa</DropdownItem>
                            <DropdownItem href='/full-download-report'>Download Integral</DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                    <UncontrolledDropdown nav inNavbar>
                        <DropdownToggle nav caret>
                            Relatórios Especiais
                        </DropdownToggle>
                        <DropdownMenu end>
                            <DropdownItem href='/reports/bradesco'>Bradesco Bank</DropdownItem>
                            <DropdownItem disabled>Next Bank</DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                    <NavLink className={'ms-2'} disabled={true}>
                        <small>Versão Beta 1.4</small>
                    </NavLink>
                </Nav>
                <Nav className="ms-auto" navbar>
                    <UncontrolledDropdown nav inNavbar>
                        <DropdownToggle nav caret>
                            {user?.alias ?? (user?.personCpf?.name ?? user?.personCnpj?.fantasyName)}
                        </DropdownToggle>
                        <DropdownMenu end>
                            <DropdownItem href='/users/profile'>
                                Perfil de Usuário
                            </DropdownItem>
                            <DropdownItem divider />
                            <DropdownItem onClick={logout}>Sair</DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </Nav>
            </Collapse>
        </Navbar>
    )
}

export default DefaultHeader;
